import { createWebHistory, createRouter } from "vue-router";
import Email from '../components/Email/Email.vue';
import SubmissionBuilder from '../components/SubmissionBuilder/SubmissionBuilder.vue';
// import SearchResults from '../components/Layout/SearchResults.vue';
// import SearchResultsWrapper from '../components/Layout/SearchResults/SearchResultsWrapper.vue';
// import SubCatSearchResults from '../components/Layout/SubCatSearchResults.vue';

export default createRouter({
  history: createWebHistory(),
  scrollBehavior() {
    return { x: 0, y: 0 };
  },
  routes: [
    {
      path: '/',
      component: () =>
        import(
          /* webpackChunkName: "login" */ '@/components/LandingLayout/Layout.vue'
        ),
      name: 'landinglayout',
      children: [
        {
          path: 'login',
          component: () =>
            import(
              /* webpackChunkName: "login" */ '@/components/Login/Login.vue'
            ),
          name: 'login',
          meta: {
            title: 'Login'
          }
        },
        {
          path: 'confirmlogin/:token',
          component: () =>
            import(
              /* webpackChunkName: "landing" */ '@/components/Login/ConfirmLogin.vue'
            ),
          name: 'confirmlogin',
          meta: {
            title: 'Confirm Login'
          }
        },
        {
          path: 'register',
          component: () =>
            import(
              /* webpackChunkName: "landing" */ '@/components/Register/Register.vue'
            ),
          name: 'register',
          meta: {
            title: 'Register'
          }
        },
        {
          path: 'forgot',
          component: () =>
            import(
              /* webpackChunkName: "landing" */ '@/components/Password/ForgotPassword.vue'
            ),
          name: 'forgot',
          props: true,
          meta: {
            title: 'Forgot Password'
          }
        },
        {
          path: 'verificationlink',
          component: () =>
            import(
              /* webpackChunkName: "landing" */ '@/components/VerificationLink/VerificationLink.vue'
            ),
          name: 'VerificationLink',
          props: true,
          meta: {
            title: 'Verfication Link'
          }
        },
        {
          path: 'reset/:token',
          component: () =>
            import(
              /* webpackChunkName: "landing" */ '@/components/Password/ResetPassword.vue'
            ),
          name: 'confirmreset',
          props: true,
          meta: {
            title: 'Reset Password'
          }
        },
        {
          path: 'register/:token',
          component: () =>
            import(
              /* webpackChunkName: "landing" */ '@/components/Register/ConfirmRegister.vue'
            ),
          name: 'confirmregister',
          props: true,
          meta: {
            title: 'Confirm Register'
          }
        },
        {
          path: '/terms-of-service',
          component: () =>
            import(
              /* webpackChunkName: "landing" */ '@/components/TermsOfService/TermsOfService.vue'
            ),
          name: 'termsofservice',
          props: true,
          meta: {
            title: 'Terms Of Service'
          }
        }
      ]
    },
    {
      path: '/rams-tour',
      name: 'RAMSTour',
      component: () => import('@/components/VirtualTour/RAMSTour.vue'),
      props: true,
      meta: {
        title: 'RAMSTour'
      }
    },
    {
      path: '/networkconnection',
      name: 'NetworkConnection',
      component: () => import('@/components/Common/NetworkConnection.vue'),
      props: true,
      meta: {
        title: 'Network Connection'
      }
    },
    {
      path: '/layout',
      component: () => import('@/components/Layout/Layout.vue'),
      // component: DashboardLayout,
      name: 'Layout',
      redirect: '/layout/dashboard',
      meta: {
        title: 'Dashboard'
      },
      children: [
        {
          path: 'dashboard',
          component: () =>
            import('@/components/Dashboard/DashboardWrapper.vue'),
          // component: DashboardWrapper,
          name: 'DashboardWrapper',
          props: true,
          meta: {
            title: 'Dashboard'
          },
          children: [
            {
              path: '',
              component: () =>
                import('@/components/Dashboard/DashboardLayout.vue'),
              // component: Dashboard,
              name: 'Dashboard',
              props: true,
              meta: {
                title: 'Dashboard'
              }
            },
            {
              path: ':type/add',
              component: () =>
                import('@/components/Dashboard/Actions/AddEditHeadLines.vue'),
              name: 'AddHeadLines',
              props: true,
              meta: {
                title: 'Dashboard'
              }
            },
            {
              path: ':type/:headLineId/edit',
              component: () =>
                import('@/components/Dashboard/Actions/AddEditHeadLines.vue'),
              name: 'EditHeadLines',
              props: true,
              meta: {
                title: 'Dashboard'
              }
            },
            {
              path: ':type',
              component: () =>
                import(
                  '@/components/Dashboard/Actions/EditHeadLineFeatureContent.vue'
                ),
              // component: EditHeadLineFeatureContent,
              name: 'EditHeadLineFeatureContent',
              props: true,
              meta: {
                title: 'Dashboard'
              }
            }
            // {
            //   path: '',
            //   name: 'CustomizeDashboard',
            //   component: CustomizeDashboard,
            //   props: true,
            //   meta: {
            //     title: 'Dashboard',
            //   },
            // },
          ]
        },
        {
          path: 'search-results',
          component: () =>
            import(
              '@/components/Layout/SearchResults/SearchResultsWrapper.vue'
            ),
          meta: {
            title: 'Global Search'
          },
          children: [
            {
              path: '',
              name: 'SearchResults',
              component: () =>
                import('@/components/Layout/SearchResults/SearchResults.vue'),
              meta: {
                title: 'Global Search'
              }
            },
            {
              path: 'service',
              name: 'SearchByService',
              component: () =>
                import(
                  '@/components/Layout/SearchResults/SubCatSearchResults.vue'
                ),
              meta: {
                title: 'Global Search'
              }
            }
          ]
        },
        // {
        //   path: 'search-results',
        //   component: SearchResultsWrapper,
        //   name: 'SearchResults',
        //   props: true,
        // },
        // {
        //   path: 'sub-category-results',
        //   component: SubCatSearchResults,
        //   name: 'SubCatSearchResults',
        //   props: true,
        // },
        {
          path: 'smart-docs',
          component: () =>
            import(
              /* webpackChunkName: "smartdocs" */ '@/components/SmartDocs/SmartDocsWrapper.vue'
            ),
          meta: {
            title: 'Smart Docs'
          },
          children: [
            {
              path: '',
              name: 'Smart Docs',
              component: () =>
                import(
                  /* webpackChunkName: "smartdocs" */ '@/components/SmartDocs/SmartDocs.vue'
                ),
              meta: {
                title: 'Smart Docs'
              }
            },
            {
              path: 'add-document/:id?',
              name: 'AddSmartDocument',
              component: () =>
                import(
                  /* webpackChunkName: "smartdocs" */ '@/components/SmartDocs/SmartDocAddDocument.vue'
                ),
              meta: {
                title: 'Smart Docs'
              }
            },
            {
              path: 'move-document/:id',
              name: 'moveDocument',
              component: () =>
                import(
                  /* webpackChunkName: "smartdocs" */ '@/components/SmartDocs/SmartDocsMoveFolder.vue'
                ),
              meta: {
                title: 'Smart Docs'
              }
            }
            // {
            //   path: 'doc-details/:id',
            //   component: () =>
            //     import(
            //       /* webpackChunkName: "smartdocs" */ '@/components/MyDocs/DocumentDetails.vue'
            //     ),
            //   name: 'doc-details',
            //   props: true,
            //   meta: {
            //     title: 'Smart Docs'
            //   }
            // }
          ]
        },
        {
          path: 'trainings',
          component: () => import ('@/components/LMSTrainings/LMSWrapper.vue'),
          props: true,
          children: [
            {
              path: '',
              name: 'Trainings',
              component: () =>
                import('@/components/LMSTrainings/LMSDashboard/LMSHome.vue'),
              props: true,
              meta: {
                title: 'Trainings'
              }
            },
          ]
        },
        {
          path: 'market-place',
          component: () =>
            import(
              /* webpackChunkName: "marketplace" */ '@/components/MarketPlace/MarketPlaceView.vue'
            ),
          props: true,
          children: [
            {
              path: '',
              name: 'Marketplace',
              component: () =>
                import(
                  /* webpackChunkName: "marketplace" */ '@/components/MarketPlace/MarketPlace.vue'
                ),
              props: true,
              meta: {
                title: 'Marketplace'
              }
            },
            {
              path: 'service/:serviceId',
              name: 'service',
              component: () =>
                import(
                  /* webpackChunkName: "marketplace" */ '@/components/MarketPlace/NewService.vue'
                ),
              props: true,
              meta: {
                title: 'Marketplace'
              }
            },
            {
              path: 'add-service/:serviceId',
              name: 'addService',
              component: () =>
                import(
                  /* webpackChunkName: "marketplace" */ '@/components/MarketPlace/PSM/SubscriptionFlow.vue'
                ),
              props: route => ({onefree: route.params.onefree}), 
              meta: {
                title: 'Marketplace'
              }
            },
            {
              path: 'renew-service',
              name: 'renewService',
              component: () =>
                import(
                  /* webpackChunkName: "marketplace" */ '@/components/MarketPlace/PSM/RenewalFlow.vue'
                ),
              props: true,
              meta: {
                title: 'Marketplace'
              }
            },
            {
              path: 'upgrade-service',
              name: 'UpgradeService',
              component: () =>
                import(
                  /* webpackChunkName: "marketplace" */ '@/components/MarketPlace/PSM/UpgradeFlow.vue'
                ),
              props: true,
              meta: {
                title: 'Marketplace'
              }
            }
          ]
        },
        {
          path: 'registration',
          component: () =>
            import('@/components/Registrations/Registration.vue'),
          name: 'Registration',
          props: true,
          meta: {
            title: 'Registration Tracker'
          }
        },
        {
          path: 'email/:mode/:id',
          component: Email
        },
        {
          path: 'ptemail/:marketId/:device',
          component: Email
        },
        {
          path: 'report',
          component: () =>
            import(
              /* webpackChunkName: "report" */ '@/components/Reports/Report.vue'
            ),
          name: 'report',
          meta: {
            title: 'Registration Tracker'
          },
          props: true,
          children: [
            {
              path: 'summary',
              name: 'ReportSummary',
              component: () =>
                import(
                  /* webpackChunkName: "report" */ '@/components/Reports/ReportSummary.vue'
                ),
              props: true,
              meta: {
                title: 'Registration Tracker'
              }
            },
            {
              path: 'country',
              name: 'ReportCountry',
              component: () =>
                import(
                  /* webpackChunkName: "report" */ '@/components/Reports/ReportCountry.vue'
                ),
              props: true,
              meta: {
                title: 'Registration Tracker'
              }
            },
            {
              path: 'device',
              name: 'ReportDevice',
              component: () =>
                import(
                  /* webpackChunkName: "report" */ '@/components/Reports/ReportDevice.vue'
                ),
              props: true,
              meta: {
                title: 'Registration Tracker'
              }
            },
            {
              path: 'status',
              name: 'ReportStatus',
              component: () =>
                import(
                  /* webpackChunkName: "report" */ '@/components/Reports/ReportStatus.vue'
                ),
              props: true,
              meta: {
                title: 'Registration Tracker'
              }
            }
          ]
        },
        {
          path: 'regulatoryUpdatesAdmin',
          component: () =>
            import(
              /* webpackChunkName: "regupdates" */ '@/components/RegulatoryUpdates/RegulatoryAdminWrapper.vue'
            ),
          name: 'RegulatoryUpdatesAdminWrapper',
          props: true,
          children: [
            {
              path: '',
              component: () =>
                import(
                  /* webpackChunkName: "regupdates" */ '@/components/RegulatoryUpdates/RegulatoryUpdatesAdmin.vue'
                ),
              props: true,
              name: 'RegulatoryUpdatesAdmin',
              meta: {
                title: 'Regulatory Watch Admin'
              }
            },
            {
              path: 'create-alert',
              component: () =>
                import(
                  /* webpackChunkName: "regupdates" */ '@/components/RegulatoryUpdates/AddNewAlert.vue'
                ),
              name: 'CreateAlert',
              props: true,
              meta: {
                title: 'Regulatory Watch Admin'
              }
            },
            {
              path: 'create-alert/:id',
              component: () =>
                import(
                  /* webpackChunkName: "regupdates" */ '@/components/RegulatoryUpdates/AddNewAlert.vue'
                ),
              name: 'updateAlert',
              props: true,
              meta: {
                title: 'Regulatory Watch Admin'
              }
            }
          ]
        },
        {
          path: 'regulatory-updates',
          component: () =>
            import('@/components/RegulatoryUpdates/RegUpdatesHome.vue'),
          name: 'RegulatoryUpdates',
          meta: {
            title: 'Regulatory Watch'
          }
        },
        {
          path: 'regulatory-pathway',
          name: 'RegulatoryPathway',
          component: () =>
            import('@/components/RegulatoryPathway/RegulatoryPathway.vue'),
          props: true,
          meta: {
            title: 'Regulatory Reports'
          }
        },
        {
          path: 'regulatory-intelligence',
          name: 'RegulatoryIntelligence',
          component: () => import('@/components/RegIntel/RegIntel.vue'),
          props: true,
          meta: {
            title: 'Regulatory Intelligence'
          }
        },
        {
          path: 'alert',
          name: 'notification',
          component: () => import('@/components/Notification/Notification.vue'),
          meta: {
            title: 'Notifications'
          }
        },
        {
          path: 'prodclassification-pathway-landing',
          name: 'prodclassification-pathway-home',
          component: () =>
            import(
              '@/components/ProductClassification/ProductClassificationRegPathwayLanding.vue'
            ),
          props: true,
          meta: {
            title: 'Product Classification Reg Pathway'
          }
        },
        {
          path: 'prodclassification-home',
          name: 'ProdClassificationHome',
          component: () =>
            import(
              '@/components/ProductClassification/ProductClassification.vue'
            ),
          props: true,
          meta: {
            title: 'Product Classification'
          }
        },
        {
          path: 'prod-classification-summary',
          name: 'ProdClassificationSummary',
          component: () =>
            import(
              '@/components/ProductClassification/ClassificationSummaryInfoRationaleFlow.vue'
            ),
          props: true,
          meta: {
            title: 'Product Classification Summary'
          }
        },
        {
          path: 'prod-classification-summary-kit',
          name: 'ProdClassificationSummaryKit',
          component: () =>
            import(
              '@/components/ProductClassification/ClassificationSummaryInfoRationaleFlowKit.vue'
            ),
          props: true,
          meta: {
            title: 'Product Classification Summary'
          }
        },
        {
          path: 'prodclassification',
          name: 'ProdClassification',
          component: () =>
            import(
              '@/components/ProductClassification/ProductClassificationLanding.vue'
            ),
          props: true,
          meta: {
            title: 'Product Classification'
          }
        },
        {
          path: 'ProductClassificationRationale',
          name: 'ProductClassificationRationale',
          component: () =>
            import(
              '@/components/ProductClassification/ProductClassificationRationale.vue'
            ),
          props: true,
          meta: {
            title: 'Product Classification Rationale'
          }
        },
        {
          path: 'ProductClassificationRationaleIVDR',
          name: 'ProductClassificationRationaleIVDR',
          component: () =>
            import(
              '@/components/ProductClassification/IVDR/ProductClassificationRationale.vue'
            ),
          props: true,
          meta: {
            title: 'Product Classification Rationale'
          }
        },
        {
          path: 'resourcesdashboard',
          component: () =>
            import('@/components/ResourcesDashboard/Dashboard.vue'),
          name: 'ResourcesDashboard',
          props: true,
          meta: {
            title: 'Resources Dashboard'
          }
        },
        {
          path: 'tools',
          component: () =>
            import(
              /* webpackChunkName: "tools" */ '@/components/Tools/Tools.vue'
            ),
          children: [
            {
              path: '',
              name: 'ToolsWidget',
              component: () =>
                import(
                  /* webpackChunkName: "tools" */ '@/components/Tools/ToolsWidgets.vue'
                ),
              meta: {
                title: 'Resources'
              }
            },
            {
              path: 'reviewtimecalculator',
              name: 'ReviewTimeCalculator',
              component: () =>
                import(
                  /* webpackChunkName: "tools" */ '@/components/Tools/ReviewTimeCalculator.vue'
                ),
              meta: {
                title: 'Resources'
              }
            },
            {
              path: 'classification',
              name: 'Classification',
              component: () =>
                import(
                  /* webpackChunkName: "tools" */ '@/components/Tools/Classification.vue'
                ),
              meta: {
                title: 'Resources'
              }
            },
            {
              path: 'global-feefinder',
              name: 'GlobalFeeFinder',
              component: () =>
                import(
                  /* webpackChunkName: "tools" */ '@/components/Tools/NewGlobalFeeFinder.vue'
                ),
              meta: {
                title: 'Resources'
              }
            }
          ]
        },
        {
          path: 'regulatoryfaqs',
          name: 'Faq',
          component: () =>
            import(/* webpackChunkName: "faq" */ '@/components/Faqs/index.vue'),
          props: true,
          meta: {
            title: 'Regulatory Intelligence',
            subModule: 'Faq',
          },
          children: [
            {
              path: 'country/:country',
              component: () =>
                import(
                  /* webpackChunkName: "faq" */ '@/components/Faqs/Questions.vue'
                ),
              name: 'Questions',
              props: true,
              meta: {
                title: 'Regulatory Intelligence',
                subModule: 'Faq',
              },
              children: [
                {
                  path: 'category/:categoryId/subcategory/:subcategoryId/question/:questionId',
                  component: () =>
                    import(
                      /* webpackChunkName: "faq" */ '@/components/Faqs/Answers.vue'
                    ),
                  name: 'Answers',
                  props: true,
                  meta: {
                    title: 'Regulatory Intelligence',
                    subModule: 'Faq',
                  }
                }
              ]
            }
          ]
        },
        {
          path: 'regsandguidance/:id?',
          component: () =>
            import('@/components/RegsAndGuidance/RegsAndGuidance.vue'),
          name: 'RegsAndGuidance',
          props: true,
          meta: {
            title: 'Resources'
          }
        },
        {
          path: 'regulatoryprocesscharts',
          component: () =>
            import(
              '@/components/RegulatoryProcessCharts/RegulatoryProcessChart.vue'
            ),
          name: 'RegulatoryProcessCharts',
          meta: {
            title: 'Regulatory Intelligence'
          }
        },
        {
          path: 'trackplusdashboard',
          component: () =>
            import('@/components/TrackPlusDashboard/Dashboard.vue'),
          name: 'TrackplusDashboard',
          props: true,
          meta: {
            title: 'Registration Tracker'
          }
        },
        {
          path: 'submission-builder',
          component: SubmissionBuilder,
          name: 'SubmissionBuilder',
          meta: {
            title: 'Smart Builder'
          }
        },
        {
          path: 'smart-exchange',
          component: () =>
            import('@/components/SmartExchange/SmartExchange.vue'),
          name: 'SmartExchange',
          meta: {
            title: 'Smart Exchange'
          }
        },
        {
          path: 'representationresources',
          component: () =>
            import(
              '@/components/RepresentationResources/RepresentationResources.vue'
            ),
          name: 'RepresentationResources',
          meta: {
            title: 'Representation Resources'
          },
          children: [
            {
              path: 'producttracker',
              component: () =>
                import(
                  '@/components/RepresentationResources/ProductTracker/ProductTrackerView.vue'
                ),
              name: 'ProductTrackerView',
              props: true,
              meta: {
                title: 'Representation Resources'
              },
              children: [
                {
                  path: '',
                  component: () =>
                    import(
                      '@/components/RepresentationResources/ProductTracker/ProductTracker.vue'
                    ),
                  name: 'ProductTracker',
                  props: true,
                  meta: {
                    title: 'Representation Resources'
                  }
                },
                {
                  path: 'add',
                  component: () =>
                    import(
                      '@/components/RepresentationResources/ProductTracker/AddProductTracker.vue'
                    ),
                  name: 'AddProductTracker',
                  props: true,
                  meta: {
                    title: 'Representation Resources'
                  }
                },
                {
                  path: 'edit',
                  component: () =>
                    import(
                      '@/components/RepresentationResources/ProductTracker/EditProductTracker.vue'
                    ),
                  name: 'EditProductTracker',
                  props: true,
                  meta: {
                    title: 'Representation Resources'
                  }
                }
              ]
            },
            {
              path: 'guidesandtemplates',
              component: () =>
                import(
                  '@/components/RepresentationResources/GuidesAndTemplates/GuidesAndTemplates.vue'
                ),
              name: 'GuidesAndTemplates',
              props: true,
              meta: {
                title: 'Representation Resources'
              }
            },
            {
              path: 'agreements',
              component: () =>
                import(
                  '@/components/RepresentationResources/Agreements/AgreementsWrapper.vue'
                ),
              name: 'Agreements',
              props: true,
              meta: {
                title: 'Representation Resources'
              },
              children: [
                {
                  path: '',
                  name: 'AgreementsView',
                  component: () =>
                    import(
                      '@/components/RepresentationResources/Agreements/AgreementsList.vue'
                    ),
                  props: true,
                  meta: {
                    title: 'Representation Resources'
                  }
                },
                {
                  path: ':id',
                  component: () =>
                    import(
                      '@/components/RepresentationResources/Agreements/PreviewAgreement.vue'
                    ),
                  props: true,
                  meta: {
                    title: 'Representation Resources'
                  }
                }
              ]
            }
          ]
        },
        {
          path: 'launchpad',
          component: () =>
            import(
              /* webpackChunkName: "launchpad" */ '@/components/LaunchPad/LaunchPad.vue'
            ),
          name: 'LaunchPad',
          redirect: '/layout/launchpad',
          meta: {
            title: 'Launch Pad'
          },
          children: [
            {
              path: '',
              component: () =>
                import(
                  /* webpackChunkName: "launchpad" */ '@/components/LaunchPad/Dashboard.vue'
                ),
              name: 'LaunchPadDashboard',
              props: true,
              meta: {
                title: 'Launch Pad'
              }
            },
            {
              path: 'submissionbuilderadmin',
              component: SubmissionBuilder,
              name: 'SubmissionBuilderAdmin',
              props: true,
              meta: {
                title: 'Launch Pad'
              }
            },
            {
              path: 'addconsultant',
              component: () =>
                import(
                  /* webpackChunkName: "launchpad" */ '@/components/SubmissionBuilder/AddConsultant.vue'
                ),
              name: 'AddConsultant',
              props: true,
              meta: {
                title: 'Launch Pad'
              }
            },
            {
              path: 'consultantslist',
              component: () =>
                import(
                  /* webpackChunkName: "launchpad" */ '@/components/SubmissionBuilder/ConsultantsList.vue'
                ),
              name: 'ConsultantsList',
              props: true,
              meta: {
                title: 'Launch Pad'
              }
            },
            {
              path: 'addprojectmanager',
              component: () =>
                import(
                  /* webpackChunkName: "launchpad" */ '@/components/SubmissionBuilder/AddProjectManager.vue'
                ),
              name: 'AddProjectManager',
              props: true,
              meta: {
                title: 'Launch Pad'
              }
            },
            {
              path: 'projectmanagerslist',
              component: () =>
                import(
                  /* webpackChunkName: "launchpad" */ '@/components/SubmissionBuilder/ProjectManagersList.vue'
                ),
              name: 'ProjectManagersList',
              props: true,
              meta: {
                title: 'Launch Pad'
              }
            },
            {
              path: 'addsalesteam',
              component: () =>
                import(
                  /* webpackChunkName: "launchpad" */ '@/components/REPRoles/AddSalesTeam.vue'
                ),
              name: 'AddSalesTeam',
              props: true,
              meta: {
                title: 'Launch Pad'
              }
            },
            {
              path: 'salesteamlist',
              component: () =>
                import(
                  /* webpackChunkName: "launchpad" */ '@/components/REPRoles/SalesTeamList.vue'
                ),
              name: 'SalesTeamList',
              props: true,
              meta: {
                title: 'Launch Pad'
              }
            },
            {
              path: 'addrepprojectmanager',
              component: () =>
                import(
                  /* webpackChunkName: "launchpad" */ '@/components/REPRoles/AddRepProjectManager.vue'
                ),
              name: 'AddREPProjectManager',
              props: true,
              meta: {
                title: 'Launch Pad'
              }
            },
            {
              path: 'repprojectmanagerlist',
              component: () =>
                import(
                  /* webpackChunkName: "launchpad" */ '@/components/REPRoles/REPProjectManagerList.vue'
                ),
              name: 'REPProjectManagerList',
              props: true,
              meta: {
                title: 'Launch Pad'
              }
            },
            {
              path: 'addrepconsultant',
              component: () =>
                import(
                  /* webpackChunkName: "launchpad" */ '@/components/REPRoles/AddRepConsultant.vue'
                ),
              name: 'AddREPConsultant',
              props: true,
              meta: {
                title: 'Launch Pad'
              }
            },
            {
              path: 'repconsultantlist',
              component: () =>
                import(
                  /* webpackChunkName: "launchpad" */ '@/components/REPRoles/REPConsultantsList.vue'
                ),
              name: 'REPConsultantList',
              props: true,
              meta: {
                title: 'Launch Pad'
              }
            },
            {
              path: 'activeaccountslist',
              component: () =>
                import(
                  /* webpackChunkName: "launchpad" */ '@/components/SubmissionBuilder/ActiveAccountsList.vue'
                ),
              name: 'ActiveAccountsList',
              props: true,
              meta: {
                title: 'Launch Pad'
              }
            },
            {
              path: 'addproject',
              component: SubmissionBuilder,
              name: 'AddProject',
              props: true,
              meta: {
                title: 'Launch Pad',
                key: 'addProject'
              }
            },
            {
              path: 'addcompany',
              component: SubmissionBuilder,
              name: 'AddCompany',
              props: true,
              meta: {
                title: 'Launch Pad',
                key: 'addCompany'
              }
            },
            {
              path: 'addproduct',
              component: SubmissionBuilder,
              name: 'AddProduct',
              props: true,
              meta: {
                title: 'Launch Pad',
                key: 'addProduct'
              }
            },
            {
              path: 'projectlist',
              component: SubmissionBuilder,
              name: 'ProjectList',
              props: true,
              meta: {
                title: 'Launch Pad',
                key: 'projectList'
              }
            },
            {
              path: 'companylist',
              component: SubmissionBuilder,
              name: 'CompanyList',
              props: true,
              meta: {
                title: 'Launch Pad',
                key: 'companyList'
              }
            },
            {
              path: 'productlist',
              component: SubmissionBuilder,
              name: 'ProductList',
              props: true,
              meta: {
                title: 'Launch Pad',
                key: 'productList'
              }
            },
            {
              path: 'exportaudit',
              component: SubmissionBuilder,
              name: 'ExportAudit',
              props: true,
              meta: {
                title: 'Launch Pad',
                key: 'exportAudit'
              }
            },
            {
              path: 'activateservice',
              component: () =>
                import(
                  /* webpackChunkName: "launchpad" */ '@/components/SubmissionBuilder/ActivateService.vue'
                ),
              name: 'ActivateService',
              props: true,
              meta: {
                title: 'Launch Pad'
              }
            },
            {
              path: 'systemnotifications',
              component: () =>
                import(
                  /* webpackChunkName: "launchpad" */ '@/components/SystemNotifications/SystemNotifications.vue'
                ),
              name: 'SystemNotifications',
              props: true,
              meta: {
                title: 'Launch Pad'
              }
            },
            {
              path: 'systemnotificationslist',
              component: () =>
                import(
                  /* webpackChunkName: "launchpad" */ '@/components/SystemNotifications/SystemNotificationsList.vue'
                ),
              name: 'SystemNotificationsList',
              props: true,
              meta: {
                title: 'Launch Pad'
              }
            },
            {
              path: 'rbac/RBACManagement',
              component: () =>
                import(
                  /* webpackChunkName: "launchpad" */ '@/components/RbacAdmin/RBACManagement.vue'
                ),
              name: 'RBACManagement',
              props: true,
              meta: {
                title: 'Launch Pad'
              }
            }
          ]
        },
        {
          path: 'activitytracker',
          component: () =>
            import('@/components/ActivityTracker/ActivityTracker.vue'),
          name: 'activitytracker',
          meta: {
            title: 'Activity Tracker'
          }
        },
        {
          path: 'accountdetails',
          component: () =>
            import('@/components/AccountDetails/AccountDetails.vue'),
          name: 'AccountDetails',
          props: true,
          meta: {
            title: 'Activity Tracker'
          }
        },
        {
          path: 'prodclassificationactivitytracker',
          component: () =>
            import(
              '@/components/ActivityTracker/ProductClassificationActivityTracker.vue'
            ),
          name: 'Product Classification',
          props: true,
          meta: {
            title: 'Activity Tracker'
          }
        },
        {
          path: 'PermissionAccess',
          component: () => import('@/components/Common/PermissionAccess.vue'),
          name: 'PermissionsAccess',
          props: true,
          meta: {
            title: 'Permission Access'
          }
        },
        {
          path: 'support',
          component: () => import('@/components/Layout/TechnicalSupport.vue'),
          name: 'support',
          props: true,
          meta: {
            title: 'Help & Support'
          }
        }
      ]
    },
    {
      path: '/:catchAll(.*)',
      redirect: '/'
    }
  ]
});


